import React, { useContext } from 'react';
import './index.scss';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { SettingsContext } from 'context/SettingsContext';
import { routeNames } from 'routes';
import { IS_BUILDNET } from 'utils/config';

const Headband = () => {
  const { showHeadband, setShowHeadband } = useContext(SettingsContext);
  if (IS_BUILDNET) return null;
  if (!showHeadband) return null;
  return (
    <div className='Headband'>
      <div></div>
      <Link to={routeNames.jelly}>
        Check your{' '}
        <a
          href='https://docs.dusa.io/concepts/jelly'
          target='_blank'
          rel='noopener noreferrer'
          className='jelly-link'
          onClick={(e) => e.stopPropagation()}
        >
          JELLY
        </a>{' '}
        allocation
      </Link>
      {/* <div className='headband-content'>
        <span className='headband-content-text'>
          <span>Incentives program is still ongoing 🔥</span>
        </span>
        <a
          className='headband-content-link'
          href='https://docs.dusa.io/concepts/rewards'
          target='_blank'
          rel='noopener noreferrer'
        >
          Discover more
          <FontAwesomeIcon icon={faExternalLink} />
        </a>
      </div> */}
      <div className='hide-headband-container'>
        <FontAwesomeIcon icon={faX} onClick={() => setShowHeadband(false)} />
      </div>
    </div>
  );
};

export default Headband;
