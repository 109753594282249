import { getAirdropMcap } from './storage';
import { Epoch, Role } from './types';
import { baseClient } from './w3';

export const U256_MAX: bigint = 2n ** 256n - 1n;
export const MIN_ALLOWANCE_BEFORE_INCREASE: bigint = 2n ** 255n;
export const SCALE_OFFSET = 128;

// Time constants (in ms)
export const ONE_MINUTE = 60 * 1000;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;
export const ONE_WEEK = 7 * ONE_DAY;
export const ONE_PERIOD = 16_000;
export const PERIOD_PER_DAY = ONE_DAY / ONE_PERIOD;

// (changes here should be reflected in the backend)
export const TIME_BETWEEN_TICKS = 5 * ONE_MINUTE;
export const TICKS_PER_DAY = ONE_DAY / TIME_BETWEEN_TICKS;

const nodeState = await baseClient.publicApi().getNodeStatus();
export const genesisTimestamp = nodeState.config.genesis_timestamp;
export const currentBlock = nodeState.last_slot.period;

export const launchTimestamp = 1_713_182_400_000; // new Date("2024-04-15").getTime()

// Autopool constants
export const autopoolConfigs = [11, 12, 13, 21, 22, 23, 31, 32, 33] as const;
export type AutopoolConfig = (typeof autopoolConfigs)[number];

const frequencies = ['30 minutes', '1 hour', '6 hours'] as const;
const nbBins = [11, 19, 31] as const;
type Frequency = (typeof frequencies)[number];
type NbBins = (typeof nbBins)[number];
type AutopoolDescription = {
  nbBins: NbBins;
  frequency: Frequency;
};
export const autopoolDescriptions: AutopoolDescription[] = [
  { nbBins: 11, frequency: '30 minutes' },
  { nbBins: 11, frequency: '1 hour' },
  { nbBins: 11, frequency: '6 hours' },
  { nbBins: 19, frequency: '30 minutes' },
  { nbBins: 19, frequency: '1 hour' },
  { nbBins: 19, frequency: '6 hours' },
  { nbBins: 31, frequency: '30 minutes' },
  { nbBins: 31, frequency: '1 hour' },
  { nbBins: 31, frequency: '6 hours' }
];

const genesisEpoch: Epoch = {
  id: 0,
  from: new Date('2024-04-15T00:00:00Z'),
  to: new Date('2024-04-21T23:59:59Z')
};

// epoch 0 to 52, 1 epoch per week
export const epochDuration = 7 * ONE_DAY;
export const genesis = genesisEpoch.to.getTime();
const everyEpochs: Epoch[] = [
  genesisEpoch,
  ...Array.from({ length: 52 }, (_, i) => ({
    id: i + 1,
    from: new Date(genesis + i * epochDuration),
    to: new Date(genesis + (i + 1) * epochDuration - 1)
  }))
];

// past and current epochs
export const epochs = everyEpochs
  .filter((epoch) => new Date() >= epoch.from)
  .slice(-5);

export const currentEpoch =
  epochs.find((epoch) => new Date() >= epoch.from && new Date() <= epoch.to) ||
  epochs[epochs.length - 1];

export const roles: Role[] = [
  { name: 'DUSA AMBASSADOR', id: '1110937453658918952', boost: 1.35 },
  { name: 'LEGENDARY DUSER', id: '1023582914564341810', boost: 1.3 },
  { name: 'OG DUSER', id: '1090289346084610150', boost: 1.2 },
  { name: 'EARLY DUSER', id: '1119709035688632360', boost: 1.15 }
];

export const unknownURI = 'https://pngimg.com/d/question_mark_PNG99.png';

// Points
export const MAKER_MULTIPLIER = 4; // swap value multiplier
export const MCAP = 10_000_000;
export const SUPPLY = 100_000_000;
export const JELLY_VALUE = getAirdropMcap() / SUPPLY;

const POINTS_PROGRAM_LAUNCH = new Date('2024-04-12');
const weeksElapsed = Math.round(
  (Date.now() - POINTS_PROGRAM_LAUNCH.getTime()) / (7 * 24 * 60 * 60 * 1000)
);
export const WEEKLY_POINTS = 1_000_000;
export const WEEKLY_JELLY = 50_000;
export const POINTS_BY_JELLY_BEFORE_37 = 36 / 1.5; // for the 36 first epochs (24)
export const POINTS_BY_JELLY = WEEKLY_POINTS / WEEKLY_JELLY; // (24)
export const POINT_VALUE = JELLY_VALUE / POINTS_BY_JELLY;

export const TOTAL_POINTS = weeksElapsed * WEEKLY_POINTS;

export const BLACKLIST = [
  'AU1Vy3NyCGaZe8r84C4J5bGuCVbcQfaSZMftWWNRZUSTBmLWsxmc',
  'AU1P3VLNRVcT5BT9Gax6ndB1AVvaHtfNnNKfYJx6quRRWPPbh57F',
  'AU1N5omXKidm4YQsiCP9fCw1NgxDKEwNruzcLRSeWfgRWHfXzQob'
  // 'AU12TGkYSv4ompus3yv3hRo5WiJJyoTQRLPmYWm5csRzDuFs9qVF1' removed from blacklist (30% of its points were given back)
];
