import React, { useContext, useState } from 'react';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'components/Dropdown';
import SwitchButton from 'components/SwitchButton';
import './index.scss';
import { SettingsContext } from 'context/SettingsContext';
import { FEATURE_FLAGS } from 'utils/config';
import { MCAP } from 'utils/constants';
import { formatNumber, printUSD } from 'utils/methods';

const GlobalSettings = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const {
    interfaceTheme,
    setInterfaceTheme,
    isOverlay,
    setIsOverlay,
    airdropMcap,
    setAirdropMcap
  } = useContext(SettingsContext);
  const [airdropMcapValue, setAirdropMcapValue] = useState(
    formatNumber(airdropMcap)
  );

  const minMcap = 1;
  const maxMcap = 1_000_000_000;
  const handleAirdropMcapChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === '' ? minMcap : parseFloat(e.target.value);
    if (value < minMcap) setMCAP(minMcap);
    else if (value > maxMcap) setMCAP(maxMcap);
    else setMCAP(value);
  };

  const setMCAP = (value: number) => {
    setAirdropMcap(value);
    setAirdropMcapValue(value.toString());
  };

  const toggleTheme = (style: string) => {
    if (interfaceTheme === style) {
      if (style === 'Dusa') {
        setInterfaceTheme('Halloween');
      } else setInterfaceTheme('Dusa');
      return;
    }
    setInterfaceTheme(style);
  };

  return (
    <Dropdown
      isOpen={showDropDown}
      setIsOpen={setShowDropDown}
      triggerButton={
        <div className='trigger'>
          <FontAwesomeIcon icon={faGear} />
        </div>
      }
      position='bottom left'
    >
      <div className='settings-container'>
        {FEATURE_FLAGS.POINTS_V2 && (
          <div className='global-setting'>
            <div className='dusa-fdv-container'>
              <h3>JELLY Settings</h3>
              <div className='dusa-fdv-content'>
                <span style={{ fontStyle: 'italic' }}>
                  JELLY is currently non-transferable.
                </span>
                <span>
                  Set & save a fully diluted valuation below to update the net
                  APR.
                </span>
                <div className='dusa-fdv-values'>
                  <span>$</span>
                  <div className='custom-fdv'>
                    <input
                      // type='number'
                      step={100000}
                      inputMode='decimal'
                      min={minMcap}
                      max={maxMcap}
                      value={airdropMcapValue}
                      placeholder={printUSD(MCAP, false)}
                      onChange={handleAirdropMcapChange}
                      onFocus={() =>
                        setAirdropMcapValue(
                          airdropMcapValue.replace(/[,.]/g, '')
                        )
                      }
                      onBlur={() =>
                        setAirdropMcapValue(formatNumber(airdropMcap))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='global-setting'>
          <h3>Interface Settings</h3>
          <div className='settings-content'>
            {[
              { theme: 'Dusa 🪼' },
              { theme: 'Halloween 🎃' },
              { theme: 'Winter ❄️', sub: 'Snowflakes' }
            ].map((item) => {
              const theme = item.theme.split(' ')[0];
              const sub = item.sub;
              return (
                <div className='settings-theme' key={theme}>
                  <div className='settings-theme-item'>
                    <span>{item.theme}</span>
                    <SwitchButton
                      isActive={interfaceTheme === theme}
                      onClick={() => toggleTheme(theme)}
                    />
                  </div>
                  {sub === 'Snowflakes' && interfaceTheme === 'Winter' && (
                    <div className='settings-theme-item'>
                      <span className='tabulation'>{sub}</span>
                      <SwitchButton
                        isActive={isOverlay}
                        onClick={() => setIsOverlay(!isOverlay)}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Dropdown>
  );
};

export default GlobalSettings;
