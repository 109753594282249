import { EventDecoder } from '@dusalabs/sdk';
import { Args } from '@massalabs/massa-web3';
import { MIN_ALLOWANCE_BEFORE_INCREASE } from './constants';
import { getMasIfWmas } from './coreMethods';
import {
  getPairAddressTokens,
  getTokensFromPairAddress,
  getTokensSorted
} from './datastoreFetcher';
import {
  getTokenFromAddress,
  getIntervalFromSeconds,
  printBigintIsh
} from './methods';
import { MASSA, WMAS } from './tokens';
import { Token } from './types';

export const getSwapDescription = async (
  events: string[],
  params: Args,
  isNativeIn: boolean,
  isNativeOut: boolean
): Promise<string> => {
  const swapLists: string[][] = [];
  let currentSwapList: string[] = [];

  for (let i = 0; i < events.length; i++) {
    const item = events[i];

    if (item === 'TRANSFER SUCCESS') {
      if (currentSwapList.length) {
        swapLists.push(currentSwapList);
        currentSwapList = [];
      }
    } else if (item.startsWith('SWAP')) {
      currentSwapList.push(item);
    }
  }

  if (currentSwapList.length) {
    swapLists.push(currentSwapList);
  }

  swapLists.length > 2 && swapLists.splice(1, swapLists.length - 2);

  const swapAmounts = swapLists.map((swapEvents) => {
    return swapEvents.reduce(
      (prev, event) => {
        const { amountInToBin, amountOutOfBin, feesTotal } =
          EventDecoder.decodeSwap(event);

        prev.amountIn += amountInToBin + feesTotal;
        prev.amountOut += amountOutOfBin;

        return prev;
      },
      { amountIn: 0n, amountOut: 0n }
    );
  });

  const pathAddressesIndex = isNativeIn ? 2 : 3;
  const pathAddresses: string[] =
    params.getArgsList()[pathAddressesIndex].value;

  const tokenIn = isNativeIn ? MASSA : getTokenFromAddress(pathAddresses[0]);
  const tokenOut = isNativeOut
    ? MASSA
    : getTokenFromAddress(pathAddresses[pathAddresses.length - 1]);

  return `Swap ${printBigintIsh(tokenIn, swapAmounts[0].amountIn)} ${
    tokenIn.symbol
  } for ${printBigintIsh(
    tokenOut,
    swapAmounts[swapLists.length === 1 ? 0 : 1].amountOut
  )} ${tokenOut.symbol}`;
};

export const getAddLiquidityDescription = async (
  events: string[],
  params: Args
): Promise<string> => {
  const depositEvents = events.filter((e) => e.startsWith('DEPOSITED_TO_BIN:'));
  const amounts = computeLiquidity(depositEvents);

  const tokenX = getMasIfWmas(
    getTokenFromAddress(params.getArgsList()[0].value)
  );
  const tokenY = getMasIfWmas(
    getTokenFromAddress(params.getArgsList()[1].value)
  );

  return `Liquidity added with ${printBigintIsh(tokenX, amounts.amountX)} ${
    tokenX.symbol
  } and ${printBigintIsh(tokenY, amounts.amountY)} ${tokenY.symbol}`;
};

export const getRemoveLiquidityDescription = async (
  events: string[],
  params: Args
): Promise<string> => {
  const withdrawnEvents = events.filter((e) =>
    e.startsWith('WITHDRAWN_FROM_BIN:')
  );
  const feesCollectedEvents = events.filter((e) =>
    e.startsWith('FEES_COLLECTED:')
  );
  const amounts = computeLiquidity(withdrawnEvents);
  const feeAmounts = EventDecoder.decodeCollectFees(feesCollectedEvents[0]);

  const isNativeIn = params.getArgsList()[1].type !== 0; // type 0 -> string (address)
  const binStep = params.getArgsList()[isNativeIn ? 1 : 2].value;
  const tokenAAddress = params.getArgsList()[0].value;
  const tokenBAddress = isNativeIn
    ? WMAS.address
    : params.getArgsList()[1].value;
  const tokens = await getTokensSorted(tokenAAddress, tokenBAddress, binStep);
  const token0 = getMasIfWmas(tokens.token0);
  const token1 = getMasIfWmas(tokens.token1);

  let description = 'Liquidity removed with ';

  if (amounts.amountX > 0)
    description += `${printBigintIsh(token0, amounts.amountX)} ${
      token0.symbol
    }`;
  if (amounts.amountX > 0 && amounts.amountY > 0) description += ' and ';
  if (amounts.amountY > 0)
    description += `${printBigintIsh(token1, amounts.amountY)} ${
      token1.symbol
    }`;

  if (feeAmounts.amountX > 0 || feeAmounts.amountY > 0) {
    description += ' (collected ';
    if (feeAmounts.amountX > 0)
      description += `${printBigintIsh(token0, feeAmounts.amountX)} ${
        token0.symbol
      }`;
    if (feeAmounts.amountX > 0 && feeAmounts.amountY > 0)
      description += ' and ';
    if (feeAmounts.amountY > 0)
      description += `${printBigintIsh(token1, feeAmounts.amountY)} ${
        token1.symbol
      }`;
    description += ' in fees)';
  }

  return description;
};

export const getClaimFeesDescription = async (
  pairAddress: string,
  events: string[]
): Promise<string> => {
  const { amountX, amountY } = EventDecoder.decodeCollectFees(
    events[events.length - 1]
  );
  const tokens = await getPairAddressTokens(pairAddress);
  const tokenIn = getTokenFromAddress(tokens[0]);
  const tokenOut = getTokenFromAddress(tokens[1]);

  let description = 'Claim ';
  if (amountX > 0)
    description += `${printBigintIsh(tokenIn, amountX)} ${tokenIn.symbol} `;
  if (amountX > 0 && amountY > 0) description += 'and ';
  if (amountY > 0)
    description += `${printBigintIsh(tokenOut, amountY)} ${tokenOut.symbol} `;
  description += 'from generated fees';

  return description;
};

export const getAllowanceDescription = async (
  params: Args,
  tokenAddress: string
): Promise<string> => {
  const amount = params.getArgsList()[1].value;
  const token = getTokenFromAddress(tokenAddress);
  const description =
    amount < BigInt(MIN_ALLOWANCE_BEFORE_INCREASE)
      ? `
    Increase allowance for ${printBigintIsh(token, amount)} ${token.symbol}`
      : `Increase unlimited allowance for ${token.symbol}`;

  return description;
};

export const getApprovalDescription = async (
  pairAddress: string
): Promise<string> => {
  const tokens = await getPairAddressTokens(pairAddress);
  const tokenIn = getTokenFromAddress(tokens[0]);
  const tokenOut = getTokenFromAddress(tokens[1]);
  return `Approved ${tokenIn.symbol}/${tokenOut.symbol} LBToken`;
};

export const getDepositDescription = async (
  events: string[],
  pairAddress: string
): Promise<string> => {
  const { amountX, amountY } = EventDecoder.decodeVault(
    events[events.length - 2]
  ); // ICO QUEST is the last event
  const tokens = await getPairAddressTokens(pairAddress);
  const token0 = getTokenFromAddress(tokens[0]);
  const token1 = getTokenFromAddress(tokens[1]);
  return `Deposit  ${printBigintIsh(token0, amountX)} ${
    token0.symbol
  } and ${printBigintIsh(token1, amountY)} ${
    token1.symbol
  } in the vault for autonomous liquidity`;
};

export const getWithdrawDescription = async (
  events: string[],
  pairAddress: string
): Promise<string> => {
  const { amountX, amountY } = EventDecoder.decodeVault(
    events[events.length - 1]
  );
  const tokens = await getPairAddressTokens(pairAddress);
  const token0 = getTokenFromAddress(tokens[0]);
  const token1 = getTokenFromAddress(tokens[1]);
  return `Withdraw ${printBigintIsh(token0, amountX)} ${
    token0.symbol
  } and ${printBigintIsh(token1, amountY)} ${
    token1.symbol
  } from autonomous liquidity`;
};

export const getStartDCADescription = async (param: Args): Promise<string> => {
  const args = param.getArgsList();
  const [
    { value: amount },
    { value: interval },
    { value: cycles },
    { value: tokenPath }
  ] = args;

  const token0 = getTokenFromAddress(tokenPath[0]);
  const token1 = getTokenFromAddress(tokenPath[tokenPath.length - 1]);

  const intervalText = getIntervalFromSeconds(parseInt(interval) / 1000);
  const intervalTextApprox = intervalText[0];

  const description = `Execute a DCA every ${intervalTextApprox} over ${
    cycles === 0 ? '∞' : cycles
  } cycles to swap ${printBigintIsh(token0, amount)} ${token0.symbol} into ${
    token1.symbol
  }`;

  return description;
};

export const getAddLimitOrderDescription = async (
  param: Args,
  event: string
): Promise<string> => {
  const args = param.getArgsList()[0].value;
  const id = EventDecoder.decodeLimitOrder(event).id;

  const { amountIn, amountOutMin, pair, swapForY } = args;

  const [token0, token1] = await getTokensFromPairAddress(pair);

  const [tokenIn, tokenOut] = swapForY ? [token0, token1] : [token1, token0];

  const description = `Limit order id ${id} opened, expected a swap of ${printBigintIsh(
    tokenIn,
    amountIn
  )} ${tokenIn.symbol} for ${printBigintIsh(tokenOut, amountOutMin)} ${
    tokenOut.symbol
  }`;

  return description;
};

export const getCreatePoolDescription = async (
  events: string[]
): Promise<string> => {
  const baseDescription = 'Pool created';
  if (!events) return baseDescription;
  const createEvents = events.filter((e) => e.startsWith('CREATE_LBPAIR'));
  if (!createEvents.length) return baseDescription;
  const { pair, binStep: bs } = EventDecoder.decodeCreateLBPair(
    createEvents[0]
  );

  const tokens = await getTokensFromPairAddress(pair);

  return `Pool created: ${getMasIfWmas(tokens[0]).symbol}/${
    getMasIfWmas(tokens[1]).symbol
  }/${bs}, pair address: ${pair}`;
};

export const getClaimRewardsDescription = async (
  events: string[],
  token: Token
): Promise<string> => {
  const [, , , amountToRelease] = EventDecoder.extractParams(events[0]);

  return `Claimed ${printBigintIsh(token, amountToRelease)} ${token.symbol} from incentives program`;
};

const computeLiquidity = (
  events: string[]
): { amountX: bigint; amountY: bigint } => {
  const amounts = events.reduce(
    (prev, event) => {
      const decoded = EventDecoder.decodeLiquidity(event);
      prev.amountX += decoded.amountX;
      prev.amountY += decoded.amountY;

      return prev;
    },
    { amountX: 0n, amountY: 0n }
  );

  return amounts;
};
