import {
  WMAS as _WMAS,
  USDC as _USDC,
  DAI as _DAI,
  WETH as _WETH,
  WETH_B as _WETH_B,
  USDT as _USDT
} from '@dusalabs/sdk';
import { CHAIN_ID, FEATURE_FLAGS, IS_BUILDNET } from './config';
import { Token } from './types';
import charlie from '../assets/img/charlie.png';
import jelly from '../assets/img/jelly_small.png';
import Massa_Brand_Red from '../assets/img/Massa_Brand_Red.svg';
import Massa_Brand_White from '../assets/img/Massa_Brand_White.svg';
import pom from '../assets/img/pom.png';

const wmas = _WMAS[CHAIN_ID];
const equals = wmas.equals;

export const MASSA: Token = new Token(
  CHAIN_ID,
  wmas.address + '_',
  wmas.decimals,
  Massa_Brand_Red,
  'MAS',
  'Massa'
);

// airdrop
const jellyAddress = IS_BUILDNET
  ? 'AS1fSJMwRMhwZW7vndDjARo47r3zHdAUMRiNh617pyN15YR1ddgu'
  : 'AS1kVpmFjPeyamyBJ77x24M2r764hd651kEZ9GjiR6uZbEQTqdrk'; // mainnet
export const JELLY: Token = new Token(
  CHAIN_ID,
  jellyAddress,
  18,
  jelly,
  'JELLY',
  'Jelly Token'
);
// governance
const dusaGovAddress = 'AS12XeCFLQA9DYoApEhMhwEkH1xe62ci2HNd41rnQ89UFr7bEoZPz';
export const DUSA_GOV: Token = new Token(
  CHAIN_ID,
  dusaGovAddress,
  18,
  '',
  'DUSA',
  'Dusa Token'
);
export const PUR: Token = new Token(
  CHAIN_ID,
  'AS133eqPPaPttJ6hJnk3sfoG5cjFFqBDi1VGxdo2wzWkq8AfZnan',
  18,
  charlie,
  'PUR',
  'Purrfect Universe'
);
export const POM: Token = new Token(
  CHAIN_ID,
  'AS1nqHKXpnFXqhDExTskXmBbbVpVpUbCQVtNSXLCqUDSUXihdWRq',
  18,
  pom,
  'POM',
  'Pepe On Massa'
);

export const tokens: Token[] = [
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  {
    ...wmas,
    logoURI: Massa_Brand_White,
    equals
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  {
    ..._USDC[CHAIN_ID],
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png',
    equals
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  {
    ..._DAI[CHAIN_ID],
    logoURI:
      'https://s3.coinmarketcap.com/static-gravity/image/47f58ac1aa854d448df91ea0e6fbfe6f.png',
    equals
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  {
    ..._WETH[CHAIN_ID],
    logoURI:
      'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
    equals
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  {
    ..._WETH_B[CHAIN_ID],
    logoURI:
      'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
    equals
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  {
    ..._USDT[CHAIN_ID],
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
    equals
  },
  MASSA
];
// eslint-enable @typescript-eslint/ban-ts-comment
!IS_BUILDNET && tokens.push(PUR);
!IS_BUILDNET && tokens.push(POM);
FEATURE_FLAGS.POINTS_V2 && tokens.push(JELLY);

export const WMAS = tokens[0];
export const USDC = tokens[1];
export const DAI = tokens[2];
export const USDT = tokens[5];
export const WETH = tokens[3];
export const WETH_B = tokens[4];

export const tags = ['meme', 'stable', 'popular', 'defi'] as const;
type Tag = (typeof tags)[number];
export const tokenToTags: Record<string, Tag[]> = {
  [WMAS.address]: ['popular'],
  [USDC.address]: ['stable', 'popular'],
  [DAI.address]: ['stable'],
  [USDT.address]: ['stable'],
  [WETH.address]: ['popular'],
  [PUR.address]: ['meme'],
  [POM.address]: ['meme']
};
